<template>
  <div class="typeList">
    <div class="content">
      <!-- 搜索 -->
      <div class="queryBoxOne">
        <div class="btnBox"></div>
        <div class="queryItem">
          <el-input
            class="w240"
            placeholder="请输入一级分类名称"
            v-model="formData.query"
            @keyup.enter="getList"
            clearable
            v-elInput
          >
          </el-input>
          <div class="queryBtn">
            <el-button type="primary" @click="getList"
              ><el-icon><Search /></el-icon
            ></el-button>
          </div>
        </div>
      </div>
      <!-- 搜索 end-->
      <!-- 列表 -->
      <div class="tabList dp_f">
        <div class="type_one single">
          <div class="list_title dp_f">
            <div class="title">一级分类</div>
            <div class="btn " @click="changeUpdataStatus(1)" :class="isUpdateOne?'disabled':''">{{updateListOne?'完成编辑':'编辑'}}</div>
          </div>
          <div class="addBtnBox mt_20">
            <el-input class="w240"  v-elInput placeholder="请添加分类" maxlength="20" v-model="addDataOne.classifyName" ref='addInputOne' clearable  @keyup.enter="addType(1)" v-if="addTypeOne"></el-input>
            <div class="addBtn cur_p" @click="handleAddType(1)" v-else>+点击添加一级分类</div>
            <div class="add_btn_box" v-show='addTypeOne'>
               <div class="zh_type_control_btn mr_16" @click="addType(1)">添加</div>
               <div class="zh_type_control_btn" @click='addTypeOne = false'>取消</div>
            </div>
          </div>
          <el-table
            :data="typeListOne"
            style="width: 100%"
            table-layout="auto"
            :height="height"
            :show-header="false"
            class="zh-no-table-border type_list"
            :row-class-name="tableRowClassNameOne"
            :header-row-style="{ border: 'none' }"
          >
            <el-table-column
              align="center"
            >
              <template #default="scope">
                <div class="type_item cur_p" @click.stop='checkTypeOne(scope.$index)'>
                    <div class="index">{{scope.$index + 1}}</div>
                    <div class="type_name" v-if="!scope.row.isUpdate">{{scope.row.classifyName}}</div>
                  <div class="type_name" @click.stop="stopEvent" v-else>
                    <el-input  v-elInput class="w240" placeholder="分类名称" maxlength="20" v-model="scope.row.changeValue"
                              @keyup.enter="handleOkchangeType(scope.$index,1)"></el-input>
                    </div>
                  <div class="updata_box" v-if="scope.row.classifyId > 0">
                      <div class="btn mr_16 cur_p animate__animated animate__zoomIn " @click.stop='handleOkchangeType(scope.$index,1)' v-show='updateListOne && scope.row.isUpdate'>确认</div>
                      <div class="btn mr_16 cur_p animate__animated animate__zoomIn "
                           @click.stop='handelCancelType(scope.$index,1)' v-show='updateListOne && scope.row.isUpdate'>
                        取消
                      </div>
                      <div class="btn mr_16 cur_p animate__animated animate__zoomIn " @click.stop='changeType(scope.$index,1)' v-show='updateListOne && !scope.row.isUpdate'>编辑</div>
                      <div class="btn cur_p animate__zoomIn animate__animated "
                           @click.stop="detType(scope.row.classifyId,1)" v-show="updateListOne && !scope.row.isUpdate">
                        删除
                      </div>
                    </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="type_two single">
          <div class="list_title dp_f">
            <div class="title animate__animated animate__zoomIn" v-show='typeListOne[tabChecked]?.classifyName'>二级分类-{{typeListOne[tabChecked]?.classifyName}}({{typeListOne[tabChecked]?.childList?.length}})</div>
            <div class="btn animate__animated animate__zoomIn" v-show="typeListOne[tabChecked]?.classifyName" @click="changeUpdataStatus(2)"  :class="isUpdateTwo?'disabled':''">{{updateListTwo?'完成编辑':'编辑'}}</div>
          </div>
          <div class="type_two_content mt_20" :class="typeListTwo.length?'active':''">
             <div class="addBtnBox" >
               <el-input  v-elInput class="w240"  placeholder="请添加分类" maxlength="20" v-model="addDataTwo.classifyName" @keyup.enter="addType(2)" ref='addInputTwo' v-if="addTypeTwo"></el-input>
               <div class="addBtn cur_p" v-show="typeListOne[tabChecked]?.classifyName" @click="handleAddType(2)" v-else>{{addStr}}</div>
               <div class="add_btn_box" v-show='addTypeTwo'>
               <div class="zh_type_control_btn mr_16" @click="addType(2)">添加</div>
               <div class="zh_type_control_btn" @click='addTypeTwo = false'>取消</div>
            </div>
            </div>
          <el-table
            :data="typeListTwo"
            style="width: 100%"
            table-layout="auto"
            :show-header="false"
            :height="height"
            class="zh-no-table-border type_list"
            :row-class-name="tableRowClassNameTwo"
            :cell-style="{ border: 'none' }"
            :header-row-style="{ border: 'none' }"
          >
             <el-table-column
              align="center"
            >
              <template #default="scope">
                <div class="type_item cur_p " :class="!updateListTwo?'status2':''">
                    <div class="index">{{scope.$index + 1}}</div>
                    <div class="type_name" v-if="!scope.row.isUpdate">{{scope.row.classifyName}}</div>
                   <div class="type_name" @click.stop="stopEvent" v-else>
                     <el-input  v-elInput class="w240" placeholder="分类名称" maxlength="20" v-model="scope.row.changeValue"
                               @keyup.enter="handleOkchangeType(scope.$index,2)"></el-input>
                    </div>
                    <div class="updata_box" >
                      <div class="bind-btn-box" v-if="!updateListTwo">
                        <div class="btn cur_p animate__animated animate__zoomIn" @click='bindShow(scope.row)'>
                          绑定产品({{ scope.row.bindNum || 0 }})
                        </div>
                        <div class="btn cur_p animate__animated animate__zoomIn ml_32"
                             @click='openChangeType(scope.row)'>
                          修改上级分类
                        </div>
                      </div>
                      <div class="btn mr_16 cur_p animate__animated animate__zoomIn " @click.stop='handleOkchangeType(scope.$index,2)' v-show='updateListTwo && scope.row.isUpdate'>确认</div>
                      <div class="btn mr_16 cur_p animate__animated animate__zoomIn "
                           @click.stop='handelCancelType(scope.$index,2)' v-show='updateListTwo && scope.row.isUpdate'>
                        取消
                      </div>
                      <div class="btn mr_16 cur_p animate__animated animate__zoomIn " @click.stop='changeType(scope.$index,2)' v-show='updateListTwo && !scope.row.isUpdate'>编辑</div>
                      <div class="btn cur_p animate__zoomIn animate__animated "
                           @click.stop="detType(scope.row.classifyId,2)" v-show="updateListTwo && !scope.row.isUpdate">
                        删除
                      </div>
                    </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          </div>
        </div>
      </div>
      <!-- 分页 -->
      <!--      <div class="mainPage">-->
      <!--        <el-pagination-->
      <!--          v-model:current-page="pageInfo.pageNumber"-->
      <!--          layout="total, prev, pager, next , jumper"-->
      <!--          :total="pageInfo.total"-->
      <!--          v-model:page-size="pageInfo.pageSize"-->
      <!--        />-->
      <!--      </div>-->
    </div>
    <Bind v-model="bindDia" :title="`分类名称:${bindTitle?bindTitle:'未知'}`" :classifyId='bindClassifyId' @close='closeAdd'></Bind>
    <change-type-dialog ref="changeTypeRefs" :type-list="typeListOne" @change="changeOldType"
                        :class-info="chooseType"></change-type-dialog>
  </div>
</template>
<script>
import {ArrowDown, Plus, Search} from "@element-plus/icons-vue";
import Bind from './compoents/bind.vue';

export default {
  name: "typeList",
  components: {
    Plus,
    ArrowDown,
    Search,
    Bind
  },
  setup() {
    return {};
  },
};
</script>

<script setup>
import { reactive, ref, getCurrentInstance, computed } from "vue";
import { ElMessageBox } from "element-plus";
import {
  typeInfo,
  typeAdd,
  typeEdit,
  typeDel,
  classifyProductList,
} from "@/utils/api/type.js"; // 分类接口
import changeTypeDialog from "@views/product/type/compoents/changeType.vue";
import { useEventListener } from "@/utils/tool/event.js"; // 工具函数
const {proxy} = getCurrentInstance();
const changeTypeRefs = ref(null)
const chooseType = ref({})
const openChangeType = (e) => {
  chooseType.value = e
  changeTypeRefs.value.showChange = true
}
const tableRowClassNameOne = ({row, rowIndex}) => {
  if (tabChecked.value === rowIndex) {
    return 'active-row'
  }
   if (rowIndex %2 === 0) {
      return 'even-row '
  }
    return 'radix-row'
};
const addInputTwo = ref('')
const addInputOne = ref('')
//确认绑定商品后刷新列表
const closeAdd= ()=>{
  bindDia.value = false
  getList()
}

//防止击穿事件
const stopEvent = (e) => {
  e.stopPropagation()
}

const tableRowClassNameTwo = ({row, rowIndex}) => {
   if (rowIndex %2 === 0) {
      return 'tab2_even-row '
  }
    return 'tab2_radix-row'
};
const height = ref(0)
const tabHeight = ()=>{
  height.value = document.documentElement.clientHeight -370;
}
tabHeight()
useEventListener(window,'resize',tabHeight)

const formData = reactive({
  query: "", // 搜索
});
const pageInfo = reactive({
  pageNumber: 1, // 当前页
  pageSize: 10, // 每页条数
  total: 0, // 总条数
});
const typeListOne = ref([]);
const typeListTwo = ref([]);
const updateListOne = ref(false);
const updateListTwo = ref(false);
const addTypeOne = ref(false)
const addTypeTwo = ref(false)
const addDataOne = reactive({
  classifyName:"",
})
const addDataTwo = reactive({
  classifyName:"",
  parentId:""
})
const bindDia = ref(false);
const bindClassifyId = ref('')
const bindTitle = ref('')
const bindShow = (e)=>{
  bindDia.value = true
  bindTitle.value = e.classifyName
  bindClassifyId.value = e.classifyId
}
//控制一级分类选中
const tabChecked = ref(null);

const addStr = computed(() => {
  if (tabChecked.value == null) {
    return "请选择一级分类，再添加二级分类";
  }
  if (typeListTwo.value.length > 0) {
    return "+点击添加二级分类";
  }
  return "暂无二级分类，请添加";
});

/*
  选择一级分类
*/
const checkTypeOne = (row) => {
  if (tabChecked.value === row) {
    tabChecked.value = null;
    typeListTwo.value = []
    return
  }
  tabChecked.value = row;
  typeListTwo.value = typeListOne.value[row].childList;
};
/*
  获取分类列表
*/
const getList = ()=>{
  typeInfo({
    query:formData.query,
    pageNumber:pageInfo.pageNumber,
    pageSize:pageInfo.pageSize
  }).then((res) => {
    if (res.code === 0) {
      typeListOne.value = res.data.list;
      if(tabChecked.value != null){
        typeListTwo.value = typeListOne.value[tabChecked.value].childList;
      }
      pageInfo.total = res.data.total;
    } else {
      proxy.$message.error(res.msg);
    }
  });
}
getList()

/*
  添加分类
*/
const handleAddType = (type=1)=>{
  if (type === 1) {
    addDataOne.classifyName = "";
    addTypeTwo.value = false;
    addTypeOne.value = true;
    setTimeout(()=>{
      addInputOne.value.focus()
    },100)
  }else{
    if(tabChecked.value == null){
      return
    }
    addTypeOne.value = false;
    addDataTwo.classifyName = "";
    addDataTwo.parentId = typeListOne.value[tabChecked.value].classifyId;
    addTypeTwo.value = true;
    setTimeout(()=>{
      addInputTwo.value.focus()
    },100)
  }
}

//确认添加分类
const addType = ( type = 1 )=>{
  let params = {};
  if (type === 1) {
    params = addDataOne;
  }else{
    params = addDataTwo;
  }
  if(!params.classifyName){
      proxy.$message.error("请输入分类名称");
      return
  }
  if(params.classifyName.length > 30){
      proxy.$message.error("分类名称不能超过30个字符");
      return
  }
  typeAdd(params).then((res) => {
    if (res.code === 0) {
      proxy.$message.success("添加成功");
      getList()
      if (type === 1) {
          addTypeOne.value = false;
        }else{
          addTypeTwo.value = false;
        }
      return
    }
      proxy.$message.error(res.msg);
  })
}


/*
编辑分类选项
*/
const changeType =(index,type)=>{
  if (type === 1) {
    typeListOne.value[index].changeValue = typeListOne.value[index].classifyName
    typeListOne.value[index].isUpdate = true;
  }else{
    typeListTwo.value[index].changeValue = typeListTwo.value[index].classifyName
    typeListTwo.value[index].isUpdate = true;
  }
}

//取消编辑
const handelCancelType = (index, type) => {
  if (type == 1) {
    typeListOne.value[index].isUpdate = false;
  } else {
    typeListTwo.value[index].isUpdate = false;
  }
}
/*
确认编辑
*/
const handleOkchangeType =(index,type)=>{
  if (type === 1) {
    //一级分类
    typeEdit({
      classifyId:typeListOne.value[index].classifyId,
      classifyName: typeListOne.value[index].changeValue
    }).then((res)=>{
      if (res.code === 0) {
        proxy.$message.success("修改成功");
        typeListOne.value[index].isUpdate = false;
        getList()
        return
      }
      proxy.$message.error(res.msg);
    })
  }else{
      //一级分类
    typeEdit({
      classifyId:typeListTwo.value[index].classifyId,
      classifyName: typeListTwo.value[index].changeValue,
      parentId:typeListTwo.value[index].parentId,
    }).then((res)=>{
      if (res.code === 0) {
        proxy.$message.success("修改成功");
        typeListTwo.value[index].isUpdate = false;
        getList()
        return
      }
      proxy.$message.error(res.msg);
    })
  }
}

/*
删除分类
*/
const detType = (id,type)=>{
  ElMessageBox.confirm("确认删除该分类吗？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    customClass: "zh-message-box",
  })
    .then(() => {
      typeDel({
        classifyId:id
      }).then((res)=>{
        if (res.code === 0) {
          proxy.$message.success("删除成功");
          if (type !== 2) {
           tabChecked.value = null
          typeListTwo.value = []
          }
          getList()
          return
        }
        proxy.$message.error(res.msg);
      })
    })
    .catch(() => {
      proxy.$message.info("已取消删除");
    });
}


const isUpdateOne = computed(()=>{
   return typeListOne.value.some((item)=>{
      return item.isUpdate
    })
})
const isUpdateTwo = computed(()=>{
   return typeListTwo.value.some((item)=>{
      return item.isUpdate
    })
})
/*
  编辑状态开启
*/
const changeUpdataStatus = (type)=>{
  if (type === 1) {
    if(typeListOne.value.some(item=> item.isUpdate)) return
    updateListOne.value = !updateListOne.value;
    typeListOne.value.forEach((item)=>{
      item.isUpdate = false;
    })
  }else{
   if(typeListTwo.value.some(item=> item.isUpdate)) return
    updateListTwo.value = !updateListTwo.value;
    typeListTwo.value.forEach((item)=>{
      item.isUpdate = false;
    })
  }
}

const changeOldType = async (e) => {
  let index = typeListOne.value.findIndex((item) => {
    return item.classifyId === e
  })
  await getList()
  checkTypeOne(index)
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
