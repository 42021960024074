<template>
  <el-dialog
    v-bind="$attrs"
    class="customerDia"
    :align-center='true'
    width="920"
    :close-on-click-modal="false"
    destroy-on-close
    @open="opneDia"
  >
   <div class="content">
      <div class="queryList">
        <div class="item">
          <div class="query_name">产品名称</div>
          <el-input
            class="w240"
            placeholder="请输入产品名称"
            v-model="formData.query"
            v-elInput
            @keyup.enter="getList"
          ></el-input>
        </div>
        <div class="item">
          <div class="query_name">产品品牌</div>
            <SelectByAbcModal typeName="brand" :maxNum="1" placeholder="请选择品牌" v-model:list="formData.brand" className="w240 mr_16" @change="getList"></SelectByAbcModal>
        </div>
        <div class="item">
          <el-button type="primary" class="searchBtn" @click="getList">
            <el-icon><Search /></el-icon>
          </el-button>
        </div>
      </div>
      <div class="tagsList" v-if="diaList.length > 0">
        <el-tag
          v-for="tag in diaList"
          :key="tag.productId"
          class="tag1"
          closable
          @close="delDiaListItem(tag)"
        >
          {{ tag.productName }}
        </el-tag>
      </div>
      <div class="tagsList" v-else>
        <div class="text">暂无添加</div>
      </div>
      <div class="search_table">
        <el-tabs v-model="tabChecked" class="demo-tabs" @tab-click="tabCheck">
        <el-tab-pane :label="'未绑定产品(' +tableData.length +')'" :name="0">
            <el-table
            :data="tableData"
            style="width: 100%"
            height="464"
            class="zh-no-table-border"
            :row-class-name="tableRowClassName"
            :cell-style="{ border: 'none' }"
            :header-row-style="{ border: 'none' }"
          >
            <el-table-column
              align="center"
              label="序号"
              width="70"
               show-overflow-tooltip
            >
              <template #default="scope">{{scope.$index + 1}}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="产品名称"
               show-overflow-tooltip
            >
              <template #default="scope">{{scope.row.productName}}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="简称"
              width="70"
              show-overflow-tooltip
            >
              <template #default="scope">{{scope.row.subName}}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="产品品牌"
              width="150"
               show-overflow-tooltip
            >
              <template #default="scope">{{scope.row.brandName}}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="产品单位"
              width="140"
               show-overflow-tooltip
            >
              <template #default="scope">{{scope.row.unitName}}
              </template>
            </el-table-column>
            <el-table-column
            prop="model"
            label="操作"
            align="center"
             width="100"
          >
              <template #default="scope">
              <div class="contBtn"  @click='handleSelectionChange(scope.row)'>{{isIn(scope.row)?'已添加':'添加'}}</div>
            </template>
          </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane :label="'已绑定产品(' +isBindList.length +')'" :name="1">
         <el-table
            :data="isBindList"
            style="width: 100%"
            height="464"
            class="zh-no-table-border"
            :row-class-name="tableRowClassName"
            :cell-style="{ border: 'none' }"
            :header-row-style="{ border: 'none' }"
          >
            <el-table-column
              align="center"
              label="序号"
              width="70"
              show-overflow-tooltip
            >
              <template #default="scope">
                <div class="table_text">{{scope.$index + 1}}</div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="产品名称"
              show-overflow-tooltip
            >
              <template #default="scope">
                <div class="table_text2">{{scope.row.productName}}</div>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="简称"
              width="70"
              show-overflow-tooltip
            >
              <template #default="scope">
                {{scope.row.subName}}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="产品品牌"
              width="150"
              show-overflow-tooltip
            >
              <template #default="scope">
               {{scope.row.brandName}}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              label="产品单位"
              width="140"
              show-overflow-tooltip
            >
              <template #default="scope">
                {{scope.row.unitName}}
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      </div>
    </div>
    <template #footer>
      <el-button @click='closeDia' class="btn1 zh-btn">取消</el-button>
      <el-button class="main btn1" :disabled="!diaList.length" type="primary" @click='saveDiaList'
        >保存</el-button
      >
    </template>
  </el-dialog>
</template>
<script setup>
//引入vue
import {
  ref,
  reactive,
  getCurrentInstance,
  watch,
  useAttrs,
  toRefs,
} from "vue";
import { useStore } from "vuex";
import { mergeList } from "@/utils/tool.js";
import { typeProductListByClassifyBind, typeProductListByUnProduct  ,typeProductList} from '@/utils/api/type.js'
//引入reactive对象重置
//设置模型
import { reactiveAssign } from "@/utils/tool.js";
//引入列表表头配置文件
import {tableRowClassName} from '@/utils/tool.js'
const props = defineProps({
  classifyId: {
    type: String,
    default: '',
  },
});
const { classifyId } = toRefs(props);
const { proxy } = getCurrentInstance();
const tabChecked = ref(0)
//未绑定产品列表
const tableData = ref([])
//已绑定产品列表
const isBindList = ref([])
const formData = reactive({
  query: "",
  brandName: "",
  brandId: "",
  brand: [],
})
const diaList = ref([])

//删除绑定产品
const delDiaListItem = (e)=>{
  let index = diaList.value.findIndex(item=>item.productId == e.productId)
  diaList.value.splice(index,1)
  tableData.value.push(e)
}

//添加绑定商品
const handleSelectionChange = (e)=>{
  if(!isIn(e)){
    diaList.value.push(e)
    let index = tableData.value.findIndex(item=>item.productId == e.productId)
    tableData.value.splice(index,1)
  }
}

//获取产品列表
const getList = ()=>{
  getUnProductList()
  getProductListByClassifyBind()
}

const opneDia = ()=>{
  resetData()
  getList()
}
//重置数据
const resetData = ()=>{
  formData.query = ''
  formData.brandName = ''
  formData.brandId = ''
  formData.brand = []
  tableData.value = []
  isBindList.value = []
  diaList.value = []
}

//查询已分类列表
function getUnProductList(){
  typeProductList({
    classifyId: classifyId.value || '',
    query:formData.query,
    brandId:formData.brand[0]?.brandId || '',
  }).then(res=>{
     if(res.code != 0 ){
      proxy.$message.error(res.msg)
      return
    }
    isBindList.value = res?.data?.list || []
  })
}


//解绑产品
const delTypeGoods = (e)=>{
}

//查询未分类列表
function getProductListByClassifyBind(){
  typeProductListByUnProduct({
     query:formData.query||'',
    brandId:formData.brand[0]?.brandId || '',
  }).then(res=>{
    if(res.code != 0 ){
      proxy.$message.error(res.msg)
      return
    }
    let list = res?.data?.list || [];
    tableData.value = mergeList(list,diaList.value,'productId')
  })
}


//判断批量添加是否已添加到列表
const isIn = (e)=>{
  let flag = false;
  diaList.value.forEach((item)=>{
    if(item.productId == e.productId){
      flag = true;
    }
  })
  return flag;
}

//确认保存列表
const saveDiaList = ()=>{
  let list = []
  if(diaList.value.length > 0){
    diaList.value.forEach((item)=>{
      list.push(item.productId)
    })
  }else{
    return
  }
  typeProductListByClassifyBind({
    classifyId: classifyId.value || '',
    productIds: list
  }).then(res=>{
    if(res.code === 0){
      closeDia()
      proxy.$message.success('绑定成功')
      return
    }
    proxy.$message.error(res.msg)
  })
}


//取消弹窗
const closeDia = ()=>{
    proxy.$emit('close')
    diaList.value = []
    formData.query = ''
    formData.brandName = ''
    formData.brandId = ''
    formData.brand = []
    tableData.value = []
    isBindList.value = []
}
</script>
<script>
import { ref, getCurrentInstance, watch, useAttrs } from "vue";
import SelectByAbcModal from '@/components/selectByAbcModal/index.vue'
import { Search } from "@element-plus/icons-vue";
export default {
  name: "customerDia",
  components: {
    Search,
    SelectByAbcModal
  },
};
</script>
<style lang="scss">
@import "./bind.scss";
</style>
