<template>
  <el-dialog
      v-model="showChange"
      title="一级分类"
      width="400"
      class="change-type-dialog"
      :close-on-click-modal="false"
      destroy-on-close
      align-center
      @open="openChange"
      @close="closeChange"
  >
    <div class="zh-dialog-content">
      <div class="dialog-content-single mb_8">
        <div class="dialog-content-key"><span class="red">*</span>分类名称</div>
        <div class="dialog-content-value">
          <el-select
              placeholder="一级分类"
              v-model="changeInfo.classifyId"
              class="w240"
          >
            <el-option v-for="item in typeList" :disabled="item.classifyId === classInfo.parentId"
                       :label="item.classifyName" :value="item.classifyId" :key="item.classifyId"/>
          </el-select>
        </div>
      </div>
    </div>
    <template #footer>
      <el-button @click="closeChange">取消</el-button>
      <el-button class="main" type="primary" :disabled="notClick" @click="handleOk">
        确认修改
      </el-button>
    </template>
  </el-dialog>
</template>

<script setup>
import {computed, getCurrentInstance, reactive, ref, toRefs} from "vue";
import {classifyChangeParent} from "@utils/api/type"

const props = defineProps({
  typeList: {
    type: Array,
    default: () => [],
  },
  classInfo: {
    type: Object,
    default: () => {
    },
  },
})
const {typeList, classInfo} = toRefs(props);
const {proxy} = getCurrentInstance();

const changeInfo = reactive({
  classifyId: '',
})

const showChange = ref(false);

function openChange() {
  changeInfo.classifyId = ''
}

function closeChange() {
  showChange.value = false;
}

const notClick = computed(() => {
  return !changeInfo.classifyId
})
const handleOk = () => {
  classifyChangeParent({
    oldParentId: classInfo.value.parentId,
    newParentId: changeInfo.classifyId,
    classifyId: classInfo.value.classifyId
  }).then(res => {
    if (res.code === 0) {
      proxy.$message.success('修改成功')
      proxy.$emit('change', changeInfo.classifyId)
      closeChange()
    } else {
      proxy.$message.error(res.msg)
    }
  })
}

defineExpose({
  showChange
})
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "changeTypeDialog",
});
</script>

<style lang="scss" src="./changeType.scss" scoped>

</style>
